import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Text } from '../../components/common/Text';

import visitLogo from '../../images/common/visitlogo.png';
import { loginSso } from '../../actions';
import LoaderGif from '../../images/loader.gif';

function CheckIfWebViewIos() {
  var userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (safari) {
      return false;
    } else if (!safari) {
      return true;
    }
  } else {
    return false;
  }
}

function isNativeApp() {
  return /AppName\/[0-9\.]+$/.test(navigator.userAgent);
}

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Logo = styled.img`
  width: 60px;
  margin-bottom: 12px;
`;

class SsoContainer extends Component {
  componentDidMount() {
    const { location, history, dispatch } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (searchParams.get('userParams') && searchParams.get('clientId')) {
      if (
        searchParams.get('clientId').includes('axis') &&
        (window.Android === undefined || window.Android === 'undefined') &&
        !window.ReactNativeWebView &&
        !CheckIfWebViewIos() &&
        !userAgent.includes('wv')
      ) {
        history.replace('/login');
      } else {
        dispatch(
          loginSso(
            searchParams.get('userParams'),
            searchParams.get('clientId'),
            history
          )
        );
      }
    } else {
      history.push({
        pathname: '/login',
        search: location.search,
      });
    }
  }

  render() {
    return (
      <OuterContainer>
        <Logo src={LoaderGif} />
      </OuterContainer>
    );
  }
}

export default connect()(SsoContainer);
