const config = {
  pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
  pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
  rootUrl: 'http://localhost:9000/',
  // apiBaseUrl: 'https://insurance-uat.getvisitapp.xyz/loki',
  // apiBaseUrl: 'https://api.samuraijack.xyz/v3',
  // apiBaseUrl: 'http://localhost:5001',
  apiBaseUrl: 'https://insurance-uat.getvisitapp.net/thunder',
  opdPurchaseUrl: 'https://opd.samuraijack.xyz',
  websiteBaseUrl: 'https://samuraijack.xyz/',
  pharmaServer: 'https://api.getvisitapp.net/absol',
  // pharmaServer: 'http://localhost:2020',
  version: '161',
  amplitudeApiKey: '2bfbcb774f5fd6372197d0e43fbda536',
  opentokApiKey: '45467122',
  clevertapId: '585-5KR-KR6Z',
  gpVerticalId: 3,
  localStorageEncryptionKey: 'visit-pwa@1.1',
  localStorageEncryptionSalt: '87e7c119e0abb08197327867035bd22e',
};
export default config;
