import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';

import { Text } from './Text';
import arrowLeft from '../../images/common/arrow-left.svg';

const HeaderContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
`;

const BackContainer = styled(Tappable)`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const HeaderText = styled(Text)`
  font-family: SF Pro Display Bold;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  color: #0b1f34;
`;

const Header = ({
  title,
  history,
  onTap,
  hideBackButton,
  color,
  textColor,
  img,
}) => (
  <HeaderContainer color={color}>
    <HeaderText color={textColor}>{title}</HeaderText>
  </HeaderContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  hideBackButton: PropTypes.bool,
  onTap: PropTypes.func,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

Header.defaultProps = {
  hideBackButton: false,
  color: 'white',
  textColor: 'black',
};

export default withRouter(Header);
