import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootSaga from '../sagas/index';
import rootReducer from '../reducers/index';

import middleware, { sagaMiddleware } from './middleware';
import CryptoJS from 'crypto-js';
import config from '../config';

const encryptionKey = config.localStorageEncryptionKey;
const encryptionSalt = config.localStorageEncryptionSalt;

const salt = CryptoJS.enc.Utf8.parse(encryptionSalt);
const key = CryptoJS.PBKDF2(encryptionKey, salt, {
  keySize: 256 / 32,
  iterations: 1000,
});
const encryptState = (state) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(state), key, {
    iv: salt,
  });
  return encrypted.toString();
};

const decryptState = (encryptedState) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedState, key, { iv: salt });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const reducer = persistReducer(
  {
    key: 'visit-pwa@1.1', // key is required
    storage, // storage is now required
    // whitelist: ['app', 'user'],
    transforms: [
      {
        // Transform the state before saving to storage
        in: (state) => encryptState(state),
        // Transform the state before loading it from storage
        out: (state) => decryptState(state),
      },
    ],
  },
  combineReducers({ ...rootReducer })
);

window.localStorage.removeItem('persist:visit-pwa');

const composeEnhancer =
  process.env.REACT_APP_BUILD_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancer(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(require('../reducers/index').default);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
