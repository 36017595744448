import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function defaultIssueSearch(searchType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/prereqs`, {
      headers,
      params: {
        t: searchType,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchIssues(searchQuery, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/search/issues`, {
      headers,
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function autoSuggest(searchQuery, searchType, lat, lng, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/search/auto-suggest`, {
      headers,
      params: {
        q: searchQuery,
        t: searchType,
        lat,
        lng,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getRelatives(userId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/users/${userId}/relatives`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addNewRelative(
  relationId,
  relativeName,
  relativeDob,
  relativePhone,
  userId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/users/${userId}/relatives`,
      {
        relationId,
        relativeName,
        relativeDob,
        relativePhone,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchAssistants(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/search/assistants`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function searchOnlineDoctors(params, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/search/specialists/online`, {
      params,
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getSlots(doctorId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/doctors/${doctorId}/slots`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function requestAppointment(
  doctorId,
  consultationId,
  slots,
  latitude,
  longitude,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  const data = {
    doctorId,
    consultationId,
    slots,
    latitude,
    longitude,
  };

  return axios
    .post(`${config.apiBaseUrl}/opd/consult/request`, data, {
      headers,
      params: {
        v: config.version,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getRequestStatus(requestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/v2/consult/request/${requestId}/status`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getRequestStatusForBlocker(requestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/opd/consult/offline/${requestId}/status?version=180000868`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function cancelSlotRequest(requestId, amount, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/v2/consult/request/${requestId}/confirm`, {
      params: {
        choice: 0,
        amount,
      },
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function rescheduleSlotRequest(
  requestId,
  dateKey,
  timingsid,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .put(
      `${config.apiBaseUrl}/opd/v2/consult/request/${requestId}/reschedule-appointment?datekey=${dateKey}&timingsid=${timingsid}`,
      null,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function confirmAlternateSlotRequest(
  requestId,
  po,
  dateKey,
  slotId,
  amount,
  authToken,
  history
) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    choice: 2,
    dateKey,
    slotId,
    amount,
    po,
  };
  const redirectToPaymentGateway = () => {
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/request/${requestId}/confirm?choice=2&dateKey=${dateKey}&slotId=${slotId}&amount=${amount}&po=${po}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/assistant`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function confirmSlotRequest(requestId, po, amount, authToken, history) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    choice: 1,
    amount,
    po,
  };
  const redirectToPaymentGateway = () => {
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/request/${requestId}/confirm?choice=1&amount=${amount}&po=${po}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/assistant`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function payForOfflineRequest(
  doctorInfo,
  consultRequestInfo,
  timingsId,
  dateKey,
  authToken,
  history
) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    doctorId: (doctorInfo || {}).doctorId,
    v: doctorInfo.verticalId,
    timingsId,
    dateKey,
    amount: (doctorInfo || {}).amount,
  };
  if (consultRequestInfo) {
    const {
      selectedIssuesIndex,
      relative,
      consultationId,
      currentLat,
      currentLong,
    } = consultRequestInfo;
    if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
      params.issues = selectedIssuesIndex.toString();
    }
    if (relative && relative.relativeId !== -1) {
      params.relativeId =
        relative.relativeId !== -1 ? relative.relativeId : undefined;
    }
    if (consultationId) {
      params.consultationId = consultationId;
    }
    if (currentLat && currentLong && currentLat !== -1 && currentLong !== -1) {
      params.latitude = currentLat;
      params.longitude = currentLong;
    }
  }
  const redirectToPaymentGateway = () => {
    const queryParams = new URLSearchParams();
    if (doctorInfo.doctorId) {
      queryParams.append('doctorId', doctorInfo.doctorId);
    }
    queryParams.append('amount', doctorInfo.finalFee);
    queryParams.append('v', doctorInfo.verticalId);
    if (consultRequestInfo) {
      const {
        selectedIssuesIndex,
        relative,
        consultationId,
        currentLat,
        currentLong,
      } = consultRequestInfo;
      if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
        queryParams.append('issues', selectedIssuesIndex.toString());
      }
      if (relative && relative.relativeId !== -1) {
        queryParams.append(
          'relativeId',
          relative.relativeId !== -1 ? relative.relativeId : undefined
        );
      }
      if (consultationId) {
        queryParams.append('consultationId', consultationId);
      }
      if (
        currentLat &&
        currentLong &&
        currentLat !== -1 &&
        currentLong !== -1
      ) {
        queryParams.append('latitude', currentLat);
        queryParams.append('longitude', currentLong);
      }
      queryParams.append('timingsId', timingsId);
      queryParams.append('dateKey', dateKey);
    }
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/offline?${queryParams.toString()}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/offline`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function searchSpecialists(
  consultationType,
  issueIds,
  latitude,
  longitude,
  city,
  verticalId,
  healthCentreId,
  filtersParam,
  searchInput,
  authToken,
  nextToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/search/pin-point`, {
      headers,
      params: {
        t: consultationType,
        i: issueIds.length === 0 ? undefined : issueIds.join(','),
        lat: latitude === -1 ? undefined : latitude,
        lng: longitude === -1 ? undefined : longitude,
        city,
        v: verticalId,
        hc: healthCentreId,
        f: filtersParam,
        q: searchInput.trim() === '' ? undefined : searchInput,
        next: nextToken,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadImageAttachment(
  file,
  userId,
  consultationId,
  doctorId,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('consultationId', consultationId);
  formData.append('doctorId', doctorId);
  formData.append('userId', userId);
  formData.append('preview', file);

  return axios
    .post(`${config.apiBaseUrl}/opd/users/${userId}/upload`, formData, {
      headers,
      onUploadProgress,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadFileAttachment(
  file,
  userId,
  consultationId,
  doctorId,
  prescription,
  onUploadProgress,
  authToken,
  reimbursementId,
  reimbursementType,
  invoice
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('preview', file);
  formData.append('consultationId', consultationId);
  formData.append('doctorId', doctorId);
  formData.append('userId', userId);
  if (prescription) {
    formData.append('prescription', 1);
  }
  if (invoice) {
    formData.append('invoice', 1);
  }
  if (reimbursementId) {
    formData.append('reimbursementId', reimbursementId);
  }
  if (reimbursementType) {
    formData.append('reimbursementType', reimbursementType);
  }

  return axios
    .post(`${config.apiBaseUrl}/opd/users/${userId}/upload`, formData, {
      headers,
      onUploadProgress,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getConsultationSummary(consultationId, userId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/opd/consultation/${consultationId}/summary/${userId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function consultAssistant(
  doctorInfo,
  consultRequestInfo,
  history,
  authToken,
  alternateNumber = null
) {
  const headers = {
    authorization: authToken,
  };
  const doctorId = doctorInfo.assistantId || doctorInfo.doctorId;
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    const queryParams = new URLSearchParams();
    queryParams.append('assistantId', doctorId);
    queryParams.append('amount', doctorInfo.finalFee);
    if (consultRequestInfo) {
      const { selectedIssuesIndex, relative } = consultRequestInfo;
      if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
        queryParams.append('issues', selectedIssuesIndex.toString());
      }
      if (relative && relative.id !== -1) {
        queryParams.append(
          'relativeId',
          relative.id !== -1 ? relative.id : undefined
        );
      }
      if (alternateNumber) {
        queryParams.append('alternateNumber', alternateNumber);
      }
    }
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/assistant?${queryParams.toString()}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  if (doctorInfo.finalFee.toString() === '0') {
    return redirectToPaymentGateway();
  }
  const params = {};
  params.assistantId = doctorId;
  params.amount = doctorInfo.finalFee;
  if (consultRequestInfo) {
    const { selectedIssuesIndex, relative } = consultRequestInfo;
    if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
      params.issues = selectedIssuesIndex.toString();
    }
    if (relative) {
      params.relativeId = relative.id !== -1 ? relative.id : undefined;
    }
  }
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/assistant`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else if (responseBody.message === 'failure') {
            history.push('/home', {
              bookingFailed: true,
              errorMessage: responseBody.errorMessage,
            });
            return;
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('/home', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('/home', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function consultSpecialist(
  doctorInfo,
  consultRequestInfo,
  history,
  authToken,
  replacePage,
  alternateNumber = null
) {
  // If offline, redirect to slot selection
  if (consultRequestInfo.consultType === 'offline') {
    console.log(consultRequestInfo);
    if (replacePage) {
      history.replace(`/consultation/appointment/${doctorInfo.doctorId}`, {
        consultRequestInfo,
        doctor: doctorInfo,
        consultationId: consultRequestInfo.consultationId,
        doctorName: doctorInfo.doctorName,
        latitude: consultRequestInfo.currentLat,
        longitude: consultRequestInfo.currentLong,
      });
    } else {
      history.push(`/consultation/appointment/${doctorInfo.doctorId}`, {
        consultRequestInfo,
        doctor: doctorInfo,
        consultationId: consultRequestInfo.consultationId,
        doctorName: doctorInfo.doctorName,
        latitude: consultRequestInfo.currentLat,
        longitude: consultRequestInfo.currentLong,
      });
    }
    return;
  }
  const headers = {
    authorization: authToken,
  };
  const redirectToPaymentGateway = () => {
    // Not on phonepe in-app or some other error.
    // send to payment
    const queryParams = new URLSearchParams();
    if (doctorInfo.doctorId) {
      queryParams.append('doctorId', doctorInfo.doctorId);
    }
    queryParams.append('amount', doctorInfo.finalFee);
    queryParams.append('sponsor', 'FITPASS');
    if (consultRequestInfo) {
      const { selectedIssuesIndex, relative, consultationId } =
        consultRequestInfo;
      if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
        queryParams.append('issues', selectedIssuesIndex.toString());
      }
      if (relative && relative.id !== -1) {
        queryParams.append(
          'relativeId',
          relative.id !== -1 ? relative.id : undefined
        );
      }
      if (consultationId) {
        queryParams.append('consultationId', consultationId);
      }
      if (alternateNumber) {
        queryParams.append('alternateNumber', alternateNumber);
      }
    }
    console.log(queryParams.toString());
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/specialist?${queryParams.toString()}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  if (doctorInfo.finalFee.toString() === '0') {
    return redirectToPaymentGateway();
  }
  const params = {};
  params.doctorId = doctorInfo.doctorId;
  params.amount = doctorInfo.finalFee;
  if (consultRequestInfo) {
    const { selectedIssuesIndex, relative, consultationId } =
      consultRequestInfo;
    if (selectedIssuesIndex && Array.isArray(selectedIssuesIndex)) {
      params.issues = selectedIssuesIndex.toString();
    }
    if (relative) {
      params.relativeId = relative.id !== -1 ? relative.id : undefined;
    }
    if (consultationId) {
      params.consultationId = consultationId;
    }
  }
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/specialist`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentsPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `home/consult`,
                failurePage: `home/consult`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-test`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else if (responseBody.message === 'failure') {
            history.push('/home', {
              bookingFailed: true,
              errorMessage: responseBody.errorMessage,
            });
            return;
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('/home', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('/home', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function fetchProfile(doctorId, lat, lng, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/doctors/${doctorId}/profile`, {
      headers,
      params: {
        lat,
        lng,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function fetchAssistantProfile(doctorId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/assistants/${doctorId}/profile`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function trackConsultationPayment(transactionId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/payment/status`,
      {
        orderId: transactionId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAddress(lat, long, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/location/decode`, {
      headers,
      params: {
        lat,
        lng: long,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export function storeConsultLocation(
  latitude,
  longitude,
  consultationId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/consultation/${consultationId}/location`,
      {
        latitude,
        longitude,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      return responseBody;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export function requestUrgentAssistance(consultationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/chats/request-urgent-assistance`,
      {
        consultationId,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.data = responseBody;
      throw error;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function requestDoctorCall(consultationId, requestType, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/connectionRequest/${consultationId}`,
      {
        requestType,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.data = responseBody;
      throw error;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getInvoice(
  doctorId,
  relativeId,
  dateKey,
  timingsId,
  type,
  lat,
  lng,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/consult/checkout`,
      {
        doctorId,
        relativeId,
        dateKey,
        timingsId,
        type,
        latitude: lat,
        longitude: lng,
      },
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.data = responseBody;
      throw error;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPreviousOfflineConsultation(relative = 'all', authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/care/offline-consults?relativeId=${relative}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function fetchPreviousOnlineConsultation(relative = 'all', authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/care/online-consults?relativeId=${relative}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getAllRelatives(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/care/relatives`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchConsultationBlocker(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/care/blockers`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function fetchVideoSessionDetails(sessionId, consultationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/video-call/video-session-info?sessionId=${sessionId}&consultationId=${consultationId}`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function onboardingRequest(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/care/onboarding-request`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.data = responseBody;
      throw error;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementRequestRegister(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  const {
    date,
    relativeId,
    latitude,
    longitude,
    v,
    doctorName,
    centerName,
    location,
    phone,
    speciality,
  } = body;

  return axios
    .get(`${config.apiBaseUrl}/opd/reimbursements/consult`, {
      headers,
      params: {
        date,
        relativeId,
        latitude,
        longitude,
        v,
        doctorName,
        centerName,
        location,
        phone,
        speciality,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.data = responseBody;
      throw error;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementDetailsForConsultation(
  reimbursementId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/opd/reimbursements/${reimbursementId}/status`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function reimbursementSubmit(body, reimbursementId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/reimbursements/${reimbursementId}/claim`,
      body,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function reimbursementCancel(reimbursementId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/reimbursements/${reimbursementId}/cancel`,
      {},
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function pendingRequestSubmit(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/opd/consult/prescription-blocker/submit/`,
      body,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function onlineConsultationCheckout(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/opd/consult/online/checkout`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchVideoSessionDetailByRoomId(roomId) {
  return axios
    .get(`${config.apiBaseUrl}/video-call/getRoomInfo?r=${roomId}`)
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateRelative(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .put(`${config.apiBaseUrl}/care/relatives`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchScheduleCardDetail(scheduleId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/schedule-status/${scheduleId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function getFreeAssessmentStatus(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/subscription/physio-assessment-status`, {
      headers,
    })
    .then((resp) => {
      console.log(resp, 'response===');
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function cancelScheduleCall(consultationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    consultationId,
    cancelledBy: 'user',
  };
  return axios
    .post(`${config.apiBaseUrl}/consultations/cancel-a-scheduled-call`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function appointmentReconfirmation(requestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    requestId,
  };
  return axios
    .post(`${config.apiBaseUrl}/opd/v2/consult/request/reconfirm`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAppointmentFinalStatus(requestId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/opd/v2/consult/request/${requestId}/booking-details`,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCallFromExecutive(requestId, number, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    requestId,
    executivePhoneNumber: number,
  };
  return axios
    .post(`${config.apiBaseUrl}/opd/v2/initiate-call`, body, {
      headers,
    })
    .then((resp) => {
      console.log(resp);
      const responseBody = resp.data;
      if (responseBody.message === 'SUCCESS') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function prescriptionBlockerPayment(
  requestId,
  amount,
  authToken,
  history
) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    amount,
    requestId,
  };
  const redirectToPaymentGateway = () => {
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/consult/prescription-blocker/checkout?amount=${amount}&requestId=${requestId}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(`${config.apiBaseUrl}/opd/consult/prescription-blocker/checkout`, {
          headers,
          params,
        })
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `consultation/preview`,
                failurePage: `consultation/preview`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-text`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('consultation/preview', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('consultation/preview', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export function payUpdatedAmountForAppointment(
  requestId,
  amount,
  authToken,
  history
) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    amount,
    choice: 1,
  };
  const redirectToPaymentGateway = () => {
    window.location.assign(
      `${
        config.apiBaseUrl
      }/opd/v2/consult/request/${requestId}/confirm?choice=1&amount=${amount}&auth=${
        authToken.split(' ')[1]
      }`
    );
  };
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      // Call the API to fetch the data context
      return axios
        .get(
          `${config.apiBaseUrl}/opd/v2/consult/request/${requestId}/confirm`,
          {
            headers,
            params,
          }
        )
        .then((res) => {
          const responseBody = res.data;
          if (responseBody.message === 'success') {
            const context = responseBody.data;
            if (sdk.isMethodSupported('openPaymentPage')) {
              history.replace('/processing-payment', {
                orderType: 'consultation',
                transactionId: responseBody.transactionId,
                successPage: `consultation/preview`,
                failurePage: `consultation/preview`,
                failureStateParameters: {
                  paymentFailed: true,
                },
              });
              return sdk.openPaymentsPage(
                'Visit',
                context,
                `${config.rootUrl}lab-text`,
                'https://getvisitapp.com/img/png/Visit_logo.png',
                [
                  {
                    'Order For': 'Doctor Consultation',
                  },
                ]
              );
            }
            throw new Error('Phonepe method not supported');
          } else {
            throw new Error('Failed to get payment context');
          }
        });
    })
    .catch((err) => {
      try {
        const parsedError = JSON.parse(err);
        if (
          parsedError &&
          (parsedError.error_code === 'PAYMENT_DISMISS' ||
            parsedError.error_code === 'PAYMENT_TIMEOUT')
        ) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('home/consult', {
            paymentFailed: true,
          });
          return;
        }
        // in case of android, it could still be a null error.
        if (parsedError === 'null' || parsedError === null) {
          store.dispatch({
            type: 'SHOW_ERROR',
            payload: {
              errorTitle: 'Something went wrong',
              errorDesc: 'Your payment failed. Please retry your payment.',
            },
          });
          history.push('consultation/preview', {
            paymentFailed: true,
          });
          return;
        }
      } catch (e) {
        //  Since we couldn't parse the error, it means it's not a string and hence not a phonepe dismiss/timeout error.
        // Not on phonepe in-app or some other error.
        // send to payment
        return redirectToPaymentGateway();
      }
    });
}

export const generateOtp = async (phone, authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': authToken,
  };
  const body = {
    phone,
    countryCode: 91,
    platform: 'WEB',
  };

  try {
    const data = await axios.post(`${config.apiBaseUrl}/new-auth/generate-otp-phone`, body, { headers })
    console.log(data)

    const respBody = data.data
      if (respBody.message === 'otpSent') {
        return respBody;
      }
      const err = new Error(respBody.errorMessage);
      err.data = respBody;
      throw err;
  } catch(err) {
    if (err.message === 'Maximum attempts reached, please try after 15 minutes') return { limit: true }
      return { error: true };
  }
}

export const verifyOtp = async (otp, phone, authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': authToken,
  };
  const body = {
    otp,
    phone,
  };
  console.log(body)

  try {
    const data = await axios.post(
      `${config.apiBaseUrl}/new-auth/verify-otp-updated`,
      body,
      { headers }
    );

    const respBody = data.data;
    if (respBody.message === 'success') {
      return respBody;
    }
    const err = new Error('Invalid response');
    err.data = respBody;
    throw err;
  } catch (err) {
    return { error: true };
  }
};