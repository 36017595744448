import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PodcastAlbumPage from './PodcastAlbumPage';
import PodcastsHome from './PodcastsHome';

class HealthContentNavigator extends Component {
    constructor(props) {
      super(props)
    
      if (!props.authToken || props.loginState !== 'LOGGED_IN') {
        this.props.history.push('/');
      }
    }
  render() {
    return (
      <Switch>
            <Route path="/health-content/podcasts-home" component={PodcastsHome} />
            <Route path="/health-content/sub-podcasts" component={PodcastAlbumPage} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(HealthContentNavigator);
