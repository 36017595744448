import React from 'react';
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';
// import loaderIcon from '../../images/common/loader.svg';
import loaderIcon from '../../images/loader.gif';
const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  font-size: ${() => `${2 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${(props) => (props.color ? props.color : 'black')};
`;

export default ({ color }) => (
  <LoadingContainer>
    <img src={loaderIcon} style={{ height: '48px', width: '48px' }} />
  </LoadingContainer>
);
