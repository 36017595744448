export const defaultState = {
  userId: null,
  authToken: null,
  userName: null,
  userEmail: null,
  countryCode: null,
  userPhone: null,
  dateOfBirth: null,
  userAge: 0,
  userGender: null,
  otpVerified: false,
  emailVerified: false,
  sponsorId: 0,
  verifiedSponsor: false,
  userUUID: null,
  platform: null,
  oldPhone: null,
  versionCode: null,
  referralCode: null,
  referralLink: null,
  createdAt: null,
  ftAvailable: false,
  sponsorCommonName: null,
  feedAvailable: false,
  qChat: false,
  inviteShareText: null,
  profileImage: null,
  inviteFitReward: null,
  supportPhone: null,
  streamId: 0,
  userChannelGroup: null,
  validateSponsor: false,
  walletBalance: 0,
  searchText: '',
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return Object.assign({}, state, action.payload.userInfo);
    case 'UNSET_USER_INFO':
      return Object.assign({}, defaultState);
    case 'SET_USER_PHONE':
      return Object.assign({}, state, {
        userPhone: action.payload,
      });
    case 'AUTH_TOKEN':
      return Object.assign({}, state, {
        authToken: action.payload,
      });
    case 'SET_FITCOIN_BALANCE':
      return Object.assign({}, state, {
        walletBalance: action.payload.balance,
      });
    case 'SET_SEARCH_TEXT':
      return Object.assign({}, state, {
        searchText: action.payload,
      });
    default:
      return state;
  }
}
